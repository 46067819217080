.noti-icon .badge {
  left: 23px;
}
.page_bg {
  background-color: #f8f8fb;
}
.mt-2 {
  margin-top: 20px !important;
}
.text-left {
  text-align: left !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}
.justify-content-center {
  justify-content: center;
}
.justify-flex-end {
  justify-content: flex-end;
}
.column-gap-20 {
  column-gap: 20px;
}

.assignment_content_container {
  position: relative;

  .content_stencil {
    width: 100px;
    height: 100px;

    img {
      width: inherit;
      height: inherit;
    }
  }
  .assignment_content_inner {
    background-color: #c4c4c499;
    width: 85px;
    height: 85px;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
    }
  }

  p {
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}
.p-15 {
  padding: 15px;
}
.custom-toast-item {
  padding: 8px 10px;
  border-radius: 8px;
  border-left: 4px solid $primary;
  min-width: 200px;
  &.custom-toast-success {
    border-left: 4px solid #61d345;
  }
  &.custom-toast-error {
    border-left: 4px solid #ff4b4b;
  }

  div {
    justify-content: start;
  }
}
.table_description {
  display: none;
}
.description_ic {
  position: relative;
}
.description_ic:hover {
  .table_description {
    display: block;
    z-index: 2;
    position: absolute;
    padding: 3px 10px;
    border-radius: 5px;
    top: 25px;
    white-space: pre-wrap;
    max-width: 150px;
    background: #e4e0e0;
    margin: 0 !important;
  }
}
.text-capitalize {
  text-transform: capitalize;
}

input#date-picker {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.invalid_text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fc3131;
}

.custom_list_select {
  margin-bottom: 0px !important;

  .form-select {
    padding: 0.25rem 1.75rem 0.25rem 0.75rem;
  }
}

.totalContent {
  display: flex;
  justify-content: center;
  width: 100%;

  .key {
    width: 50%;
    text-align: right;
    color: #74788D;
    font-weight: 400;
    font-size: 13px;

  }
  .value {
    width: 50%;
    text-align: center;
    color: #74788D;
    font-weight: 600;
    font-size: 13px;
  }
}
.total_content_border {
  width: 80%;
  margin: 0 auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}

.chrome-picker  {
  width: 75% !important;
  box-shadow: none !important;
}