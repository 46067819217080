@import '../../assets/scss/theme.scss';

body.open-sidebar .side-nav+.modal-backdrop {
    display: block;
}
.modal-backdrop.show {
    opacity: 0.5;
}
.modal-backdrop {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1040;
}
body.open-sidebar .side-nav{
    transform: translate(0);
    & + .modal-backdrop{
        display: block !important;
    }
}
@media (max-width: 1199px) {
    .side-nav {
        left: 0;
        transform: translate(-100%);
    }
}
.side-nav {
    background: $sidebar-dark-bg 0 0 no-repeat padding-box;
    border-right: 1px solid #dcdcdc;
    box-shadow: 2px 11px 10px #0000001d;
    display: flex;
    height: 100%;
    opacity: 1;
    overflow-y: scroll;
    position: fixed;
    transition: all .2s cubic-bezier(.685,.0473,.346,1);
    width: 15rem !important;
    z-index: 1050;

    .sidebar_logo {
        padding: 0 1.5rem;
        text-align: center;
        width: 15rem;

        .logo-sm {
            display: none;
        }

        div {
            line-height: 70px;
            height: 70px;
        }
    }
}

.vertical-collpsed{
    .side-nav {
        width: 5.5rem !important;

        .sidebar_logo {
            padding: 0 1.5rem;
            text-align: center;
            width: 5.5rem;
            .logo {
                display: none;
            }
            .logo-sm {
                display: block;
            }

            img {
                width: 30px;
                height: 30px;
            }
        }
        .nav-list-menu {
            .show {
                display: none;
            }
            &:hover {
               .nav-list-wrapper {
                    width: 17rem;
                    border-radius: 0px;
                    display: flex;
                    align-items: center;
                    background-color: $sidebar-dark-bg;

                    // .nav-view-icon {
                    //     width: 2rem;
                    //     display: flex;

                    //     img {
                    //         width: 20px !important;
                    //         height: 20px !important;
                    //     }
                    // }
                    .nav-view-content {
                        display: block !important;
                        margin-left: 35px;
                    }
               }
                
                .collapse {
                    display: block;

                    .sub-content {
                        display: block;
                        position: absolute;
                        background: $sidebar-dark-bg;
                        height: auto !important;

                        .nav-list-menu {
                            &:hover {
                                width: 9rem !important;
                            }
                            .nav-list-wrapper {
                                padding-left: 100px !important;

                                .nav-view-icon i {
                                    font-size: 14px !important;
                                }
                                .nav-view-content {
                                    margin-left: 0 !important;
                                }

                                &:last-child {
                                    padding-bottom: 0.5rem !important;
                                }
                            }
                        }

                    }
                }
            }
            &.active {
                .sub-content {
                    .nav-list-menu {
                        .nav-list-wrapper {
                            .nav-view-icon{
                                display: none;
                            }
                        }
                    }
                }
            }
            .show {
                .sub-content {
                    .nav-list-menu {
                        .nav-list-wrapper {
                            .nav-view-icon{
                                display: none;
                            }
                        }
                    }
                }
            }
            &:active, &:hover {
                .sub-content {
                    .nav-list-menu {
                        .nav-list-wrapper {
                            .nav-view-icon{
                                display: inline !important;
                            }
                        }
                    }
                }
            }
        }
        .nav-list-wrapper {
            // padding: 13px 20px !important;
            &.hover {
                justify-content: space-between;
            }
            min-height: 30px;
            line-height: 30px;
            transition: none;
            padding: 10px 30px;
            transition: none;

            .arrow {
                display: none !important;
            }
            .nav-view-icon i {
                font-size: 20px !important;
            }
            .nav-view-content {
                display: none !important;
            }

            
        }
    }

    .main-panel {
        width: calc(100% - 5.5rem);
    }
    .top-nav {
        left: 5.5rem;
    }
    
}

.nav-list-menu {
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    color: theme-color("darkGrey-2");
    transition: all 0.3s ease;
    ul.sub-content {
        .nav-list-wrapper {
            padding: 0.2rem 0.5rem 0.2rem 3.5rem !important;
            min-height: 30px;
            line-height: 30px;

            .nav-view-content .name {
                font-weight: 500;
                font-size: 13px;
                transition: all 0.3s cubic-bezier(0.685, 0.0473, 0.346, 1);
            }
            &.active {
                .nav-view-icon {
                    color: #ffffff !important;
                }
                .nav-view-content .name {
                    font-size: 13px;
                    color: #ffffff;
                }
                .arrow {
                    color: #ffffff;
                }
            }
            .nav-view-content {
                .name {
                    font-size: 13px !important;
                    font-weight: 400;
                    color: #a6b0cf;
                }
            }
            .nav-view-icon { 
                color: #a6b0cf;
            }
            
            &:hover {
                .nav-view-icon {
                    color: #ffffff;
                }
                .nav-view-content .name {
                    color: #ffffff;
                } 
            }
            

        }
            
        
    }
  
    .nav-list-wrapper {
        padding: 0 1rem;
        min-height: 50px;
        position: relative;
        border-radius: 5px;
        line-height: 50px;
        flex-wrap: nowrap;
        display: flex;
        align-items: center;

        &:hover {
            .nav-view-icon {
                color: #ffffff;
            }
            .nav-view-content .name {
                color: #ffffff;
            } 
        }
        li {
            border-radius: 0px;
        }
        .arrow {
            position: absolute;
            right: 0.8rem;
            font-size: 0.6rem;
            top: 50%;
            transform: translateY(-50%);
            color: #a6b0cf;
        }
        .nav-view-content {
            .name,
            .desc {
                margin-bottom: -4px;
                color: #a6b0cf;
                font-size: 13px;
                font-weight: 500;
            }
            .desc {
                color: grey;
            }
        }
        .nav-view-icon {
            margin-right: 10px;
            // display: inline-block;
            // width: 2rem;
            // color: #a6b0cf;
        }
    }
    &.profile .nav-list-wrapper {
        position: relative;
        margin: 0.5rem;
        padding: 0.5rem;
        background: #f5f6fa 0% 0% no-repeat padding-box;
        min-height: 3.5rem;

        .nav-view-icon {
            padding-left: 0.3rem;
            width: 2rem;
            font-size: 0.8rem;
        }
        .nav-view-content{
            align-self: center;
        }
    }
    &.profile{
        cursor: default;
    }
    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 0.2rem;
        z-index: 20;
        border-radius: 0px 4rem 4rem 0px;
    }
    &.active {
        color: #ffffff;
        .nav-view-icon {
            color: #ffffff;
        }
        .nav-view-content .name {
            color: #ffffff;
        }
        .arrow {
            color: #ffffff;
        }
      

    }
    &:not(.profile) li:hover {
        // background: lighten($color: theme-color("primary"), $amount: 48);
        color: #ffffff;
    }
}