@import '../../assets/scss/theme.scss';

.buttonType1 {
    color: #fff;
    background-color: #2a3042;
    border-color: #2a3042;
    text-align: center;
    border-radius: 15px;
    padding: 6px 0px;
    height: 28px;
    cursor: pointer;
}
.buttonType1:hover {
    color: #fff;
    background-color: #1b1f2b;
    border-color: #161923;
}
.create_button {
    color: #fff;
    // background-color: #00AEEF;
    // border-color: #00AEEF;
    text-align: center;
    border-radius: 3px;
    padding: 6px 10px;
    height: 28px;
    cursor: pointer;
    font-size: 11px;
    min-width: 150px;
}
.disable_create_button {
    color: #ffffff;
    background-color: #74788d;
    text-align: center;
    border-radius: 3px;
    padding: 6px 10px;
    height: 28px;
    cursor: not-allowed;
    margin: 0 0.25rem;
    font-size: 11px;
    min-width: 150px;
}
.spinner-border {
    width: 1rem;
    height: 1rem;
}