// 
// _table.scss
// 
.table_wrapper {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
}
.table {
  th {
      font-weight: $font-weight-bold;
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}


.table-custom-fields {
  height: 50px;
  color: $primary !important;
  border: none !important;
  border-radius: 0px !important;
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.orders_detail_table {
  width: 100%;

  thead tr {
    th {
      padding: 10px 5px;
      color: #99A5C1;
      font-weight: 500;
      font-size: 14px;
    }
    th:first-child{
      width: 50%;
    }
    th:not(:first-child) {
      text-align: center;
    }
  }

  tbody tr {
    td {
      padding: 10px 5px;
    }
    td:first-child {
      width: 50%;
      display: flex;
      align-items: center;

      p {
        color: $primary;
        margin-left: 20px;
      }
    }
    td:not(:first-child) {
      text-align: center;
    }
  }
    
}