tr.collapsed {
    display: none;
  }
  
  tr.expanded {
    display: table-row;
  }
  
  tr.clickable {
    cursor: pointer;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  th {
    background-color: #f7f7f7;
    font-weight: bold;
  }

tr.open {
    transition: all 0.5s ease-in-out;
  }
  